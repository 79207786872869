import React, { useState } from 'react';
import { Link } from 'gatsby';
//import github from "../img/github-icon.svg";
import logo from '../img/logo.svg';

const Navbar = ({ navIsActive, setNavIsActive }) => {
  const [navBarActiveClass, setNavBarActiveClass] = useState('');
  function toggleHamburger() {
    // toggle the active boolean in the state
    setNavIsActive(!navIsActive);
    // after state has been updated,
    if (!navIsActive) {
      // set the class in state for the navbar accordingly
      setNavBarActiveClass('is-active');
    } else {
      setNavBarActiveClass('');
    }
  }
  return (
    <nav className="navbar is-transparent" role="navigation" aria-label="main-navigation">
      <div>
        <div className="navbar-brand has-text-black">
          <Link to="/" className="logo pointer" title="logo">
            <img src={logo} alt="logo" />
          </Link>
          {/* Hamburger menu */}
          <div
            className={`navbar-burger burger ${navBarActiveClass}`}
            data-target="navMenu"
            role="menuitem"
            tabIndex={0}
            onKeyPress={() => toggleHamburger()}
            onClick={() => toggleHamburger()}
          >
            <span className='is-size-5' />
            <span className='is-size-5' />
            <span className='is-size-5' />
          </div>
        </div>
        <div id="navMenu" className={` navbar-menu pointer ${navBarActiveClass}`}>
          <div className="is-size-4 navbar-end has-text-right has-text-weight-semibold pointer">
            <Link
              activeStyle={{ color: '#f26522' }}
              onClick={() => toggleHamburger()}
              className="navbar-item"
              to="/about"
            >
              about
            </Link>
            <Link
              activeStyle={{ color: '#f26522' }}
              onClick={() => toggleHamburger()}
              className="navbar-item"
              to="/community"
            >
              community
            </Link>
            {/* <Link
              activeStyle={{ color: '#f26522' }}
              onClick={() => toggleHamburger()}
              className="navbar-item"
              to="/tags"
            >
              explore
            </Link> */}
            <Link
              activeStyle={{ color: '#f26522' }}
              onClick={() => toggleHamburger()}
              className="navbar-item"
              to="/submit"
            >
              participate
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
